import { merge } from "theme-ui"
import blogTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui"
// import { tailwind } from "@theme-ui/presets"

const colors = {
  primary: "#ed134b",
  background: "#231f20",
}

const theme = merge(blogTheme, {
  config: {
    initialColorModeName: "dark",
  },
  colors: {
    primary: colors.primary,
    secondary: "#ed3c69",
    // toggleIcon: tailwind.colors.gray[8],
    // heading: tailwind.colors.black,
    // divide: tailwind.colors.gray[4],
    // muted: tailwind.colors.gray[2],
    modes: {
      dark: {
        // text: tailwind.colors.gray[4],
        primary: colors.primary,
        secondary: colors.primary,
        // toggleIcon: tailwind.colors.gray[4],
        background: colors.background,
        // heading: tailwind.colors.white,
        // divide: tailwind.colors.gray[8],
        // muted: tailwind.colors.gray[8],
      },
    },
  },
})

export default theme